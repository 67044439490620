import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Cs2 from '../assets/cs2.png';
import backgroundImage from '../assets/oj.jpg'; // Add below -line-19--> style={backgroundStyle}



const Skills = () => {
    const backgroundStyle = {
      backgroundImage: `url(${backgroundImage})`,
      height: "97.5vh",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundColor: "rgba(10, 25, 47, 0.9)", // Set the background color with transparency
    };
  return (
    <div
      name="skills"
      className="w-full h-screen bg-[#0a192f] text-gray-300"
      style={backgroundStyle}
    >
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        
        
        <div >
          <p className="text-4xl font-bold inline border-b-4 border-[#FFFFF7] text-[#0c090a]">
            Skills
          </p>
          <p className="py-4">These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 text-center py-8">
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={HTML} alt="HTML icon" />
            <p className="my-4">HTML</p>
          </div>
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={CSS} alt="HTML icon" />
            <p className="my-4">CSS</p>
          </div>
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={JavaScript} alt="HTML icon" />
            <p className="my-4">JAVASCRIPT</p>
          </div>
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Cs2} alt="HTML icon" />
            <p className="my-4">C#</p>
          </div>
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={ReactImg} alt="HTML icon" />
            <p className="my-4">REACT</p>
          </div>
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Node} alt="HTML icon" />
            <p className="my-4">NODE JS</p>
          </div>
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Tailwind} alt="HTML icon" />
            <p className="my-4">TAILWIND</p>
          </div>
          <div className="bg-black p-4 rounded shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={GitHub} alt="HTML icon" />
            <p className="my-4">GITHUB</p>
          </div>
        </div>
      </div>




      
    </div>
  );
};

export default Skills;
