import React from 'react'
import backgroundImage from '../assets/oj.jpg'; // Add below -line-19--> style={backgroundStyle}

const Contact = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    height: "97.25vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "rgba(10, 25, 47, 0.9)", // Set the background color with transparency
  }

  return (
    <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4' style={backgroundStyle}> 
    <form method='POST' action="https://getform.io/f/c114dfdb-10d8-4a38-bad4-380503bbdb72" className='flex flex-col max-w-[600px] w-full'>
        <div className='pb-8'>
            <p className='text-4xl font-bold inline border-b-4 border-[#FFFFFF] text-[#0c090a]'>Contact</p>
            <p className='text-gray-300 py-4'> Submit the form below or shoot me an email at:</p>
            <p className='text-[#FFFFFF] py-1'><strong>msanchez33573@gmail.com</strong></p>
        </div>
        <input className='bg-[#FFFFFF] p-2' type="text" placeholder='Name' name='name' required />
        <input className='my-4 p-2 bg-[#FFFFFF]' type="email" placeholder='Email' name='email' required />
        <textarea className='bg-[#FFFFFF] p-2' name="message" rows="10" placeholder='Message'required></textarea>
        <button className='text-white border-2 hover:bg-[#3B9C9C] hover:border-[#01F9C6] px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>
    </form>
</div>
  )
}

export default Contact
  