import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll'



import backgroundImage from '../assets/oj.jpg'; // Add below -line-19--> style={backgroundStyle}

const Home = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    height: '98vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(10, 25, 47, 0.9)', // Set the background color with transparency
  };
  return (
    <div name="home" className="w-full h-screen ] " style={backgroundStyle}>
      {/*Container*/}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-[#f5f5f5]">Hello, I'm</p>
        <h1 className="text-4xl sm:text-7xl font-extrabold text-[#0c090a]">
          Matt Sanchez
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#ffffff]">
          Full Stack Developer
        </h2>
        <p className="text-[#ffffff] py-4 max-w-[700px]">
          Welcome to my profile!
        </p>
        <p className="text-[#ffffff] py-4 max-w-[700px]">
          With over a decade of experience in the logistics industry, I've
          embarked on an exciting career change and am now a certified Full
          Stack Developer specializing in building responsive web applications.
        </p>
        <p className="text-[#ffffff] py-4 max-w-[700px]">
          I bring a unique blend of skills from my logistics background, coupled
          with a passion for crafting efficient and user-friendly software
          solutions. My journey has equipped me not only with technical prowess
          but also exceptional communication, underscored by a customer-focused
          approach. I thrive on understanding user needs and translating them
          into intuitive digital experiences. Whether streamlining supply chains
          or developing innovative web solutions, my commitment to delivering
          excellence remains unwavering.

          
        </p>
        <p className="text-[#ffffff] py-4 max-w-[700px]">
        <strong>Lets work together!</strong>
        </p>
        <Link to="work" smooth={true} duration={500}>
          <div>
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#01F9C6] hover:border-[#01F9C6]">
              View Work
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3" />
              </span>
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Home
