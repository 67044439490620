import React from 'react'
import backgroundImage from '../assets/oj.jpg'; // Add below -line-19--> style={backgroundStyle}

const About = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(10, 25, 47, 0.9)', // Set the background color with transparency
  };


  return (
    <div
      name="about"
      className="w-full h-screen bg-[#0a192f] text-gray-300"
      style={backgroundStyle}
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-[#FFFFF7] text-[#0c090a]">
              About
            </p>
          </div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>Welcome to my corner of the digital world.</p>
          </div>
          <div className="space-y-4">
            <p>
              With over a decade in Full Truck Load (FTL) sales and management,
              I honed my skills growing multi-million dollar accounts through
              superior customer service.
            </p>
            <p>
              In 2020, I recognized the impact of automation on logistics and
              shifted my focus to software development. Completing a .NET
              Developer bootcamp in 2021, I joined TQL's back office financial
              team, supporting custom financial applications. As part of the
              Back Office Application Team (B.O.A.T), I contributed to the
              development of the TQL Rate matrix and supported ERP transitions,
              while gaining expertise in debugging and technical investigation.{" "}
              <strong>
                I bring this blend of customer service and software
                development passion to create user-friendly solutions that excel
                in efficiency and excellence.{" "}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About
